import { Fragment, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useParams } from "react-router-dom";
import { courseList } from "../assets/data/courseList";

const courseLists = [
  {
    courseTitle: "Front-end zhvillues (16 - 19 vjet)",
  },
  {
    courseTitle: "Bazat e uebit (12 - 15 vjet)",
  },
  {
    courseTitle: "Bota e IT-së për fëmijë (8 - 11 vjet)",
  },
  {
    courseTitle: "Front End Zhvillues - Përgatitje për karrierë (mbi 18 vjet)",
  },
  {
    courseTitle: "Inxhinieri Softuerike (mbi 16 vjet)",
  },
  {
    courseTitle: "UI/UX Design (mbi 16 vjet)",
  },
  // {
  //   courseTitle: 'Zhvillimi i aplikacioneve mobile me React Native',
  // },
  // {
  //   courseTitle: 'Matematikë',
  // },
];

const title = "Plotësoni formularin e mëposhtëm për tu regjistruar!";
// const socialTitle = "Register With Social Media";
const btnText = "Regjistrohu";

const SignupPage = () => {
  const params = useParams();
  const content = courseList.filter((item) => item.courseName == params.id);
  const [name, setName] = useState("");
  const [parentName, setParentName] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [averageGrade, setAverageGrade] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [course, setCourse] = useState(
    content.length > 0
      ? content[0].courseTitleRegistrations
      : courseLists[0].courseTitle
  );

  // States for checking the errors
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState(
    "Ju lutemi mbushni të gjitha fushat!"
  );

  const isRequiredParentInfo =
    course === courseLists[1].courseTitle ||
    course === courseLists[2].courseTitle;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = isRequiredParentInfo
      ? !name ||
        !email ||
        !age ||
        !address ||
        !phone ||
        !course ||
        !parentName ||
        !parentPhone
      : !name || !email || !age || !address || !phone || !course;

    if (requiredFields) {
      setError(true);
      return null;
    } else {
      setError(false);

      const newStudent = {
        name,
        email,
        school,
        averageGrade,
        age,
        address,
        phone,
        course,
      };

      setIsLoading(true);
      const response = fetch(
        "https://rich-surge-388919.ew.r.appspot.com/register",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newStudent),
        }
      );

      await response.then((res) => {
        if (res.status === 200) {
          setSubmitted(true);
          setIsLoading(false);
        } else {
          setCustomErrorMessage("Something went wrong");
          setError(true);
          setIsLoading(false);
        }
      });
    }
  };

  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h3>Regjistrimi u realizua me sukses. Ju faleminderit {name}.</h3>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? "" : "none",
        }}
      >
        <p
          style={{
            border: "1px solid",
            background: "#FFBABA",
            color: "#D8000C",
            padding: "10px",
          }}
        >
          {customErrorMessage}
        </p>
      </div>
    );
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Regjistrohu në Akademi"} curPage={"Register"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <form className="account-wrapper">
            {!submitted && (
              <h3 className="title" style={{ fontSize: 25 }}>
                {title}
              </h3>
            )}
            <div className="messages">{successMessage()}</div>
            {!submitted && (
              <div className="account-form">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Emri dhe mbiemri"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
                {/* <div className='form-group'>
                  <input
                    type='text'
                    name='school'
                    value={school}
                    onChange={(e) => setSchool(e.target.value)}
                    placeholder='Shkolla ku vijoni mësimin'
                  />
                </div> */}
                {/* <div className='form-group'>
                  <input
                    type='number'
                    name='grade'
                    required
                    value={averageGrade}
                    onChange={(e) => setAverageGrade(e.target.value)}
                    placeholder='Nota mesatare'
                    onKeyPress={(e) => {
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                      if (!regex.test(keyValue)) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      const pastedText = e.clipboardData.getData('text/plain');
                      const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                      if (!regex.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div> */}
                <div className="form-group">
                  <input
                    type="number"
                    name="age"
                    required
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    placeholder="Mosha"
                    onKeyPress={(e) => {
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                      if (!regex.test(keyValue)) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      const pastedText = e.clipboardData.getData("text/plain");
                      const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                      if (!regex.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="address"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Adresa"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    name="number"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Celulari"
                    onKeyPress={(e) => {
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                      if (!regex.test(keyValue)) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      const pastedText = e.clipboardData.getData("text/plain");
                      const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                      if (!regex.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="form-group" style={{ textAlign: "left" }}>
                  <label style={{ marginBottom: 5 }}>
                    Zgjedh trajnimin e dëshiruar:
                  </label>
                  <select
                    required
                    value={course}
                    onChange={(e) => setCourse(e.target.value)}
                  >
                    {content.length > 0 &&
                      courseList.map((course) => (
                        <option key={course.courseTitle}>
                          {course.courseTitleRegistrations}
                        </option>
                      ))}

                    {content.length === 0 &&
                      courseLists.map((course) => (
                        <option key={course.courseTitle}>
                          {course.courseTitle}
                        </option>
                      ))}
                  </select>
                </div>
                {isRequiredParentInfo ? (
                  <div className="form-group" style={{ textAlign: "left" }}>
                    <label style={{ marginBottom: 5 }}>
                      Të dhëna për prindin/kujdestarin:
                    </label>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        value={parentName}
                        required
                        onChange={(e) => setParentName(e.target.value)}
                        placeholder="Emri dhe mbiemri"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        name="number"
                        required
                        value={parentPhone}
                        onChange={(e) => setParentPhone(e.target.value)}
                        placeholder="Celulari"
                        onKeyPress={(e) => {
                          const keyCode = e.keyCode || e.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                          if (!regex.test(keyValue)) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          const pastedText =
                            e.clipboardData.getData("text/plain");
                          const regex = /^[0-9.]+$/; // Regular expression to allow only numbers and decimal point
                          if (!regex.test(pastedText)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {errorMessage()}
                <div className="form-group">
                  <button
                    onClick={handleSubmit}
                    className="lab-btn"
                    type="submit"
                  >
                    <span>{isLoading ? "Loading..." : btnText}</span>
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default SignupPage;
