import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './header.css';

const phoneNumber = '+389 71 30 90 90';
const address = 'Rr. Emin Duraku, Çair - Shkup';

let socialList = [
  {
    iconName: 'icofont-whatsapp',
    siteLink:
      'https://api.whatsapp.com/send?phone=38971309090&text=Link%3A%0Ahttps%3A%2F%2Ffb.me%2F2fP9UKD6m%0A%0AHello!%20Can%20I%20get%20more%20info%20on%20this%3F&source_url=https%3A%2F%2Ffb.me%2F2fP9UKD6m&icebreaker=Hello!%20Can%20I%20get%20more%20info%20on%20this%3F&app=facebook&entry_point=post_cta&jid=38971309090%40s.whatsapp.net&show_keyboard=1&show_ad_attribution=1&data=AWBurJg-KdgBxs13d4gZCuqvFU4nfT8BG1K4R6Oh0RL8cqQMpoQDZlh2gD26qU9CbaCqBFiCclp38CsLh4RpcEKrH3tQgsOoKJJhZufDtEChKjjDQKt1e1b0fd9TSxBdKSAuGdTdh_W7TPS3DqQ9r_sjHwSy2diCwgCNGzXkvZ-9AXkXKYJp2L3mMzYK6o7RQa468XqivC2GF5WJ7N_MvXJr-vBGLbFMhMI1n_iaVP06edXzbxZEp-sYs_qzlT5sRZ5cKbuyf0sKR-83tbK4rhw116s4F8YDTWQh7Hs2ZJMUFxoTyatu7leOXscj59188V0X9eu6nh_iAOkcBSgkBd5j5159iFjiIAavvA2mBO8PwoJwZSh1Y64zXI3ovAsL6mCviAaIqMHZk8vGet2i8ju2fH-VWaXadirHwv7PErmvFjedoF-sn0GDeRf8xhhKLc4OixhMhbmBy2JvGwPREX3jpWHi&source=FB_Post&token=eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjEyNSJ9.eyJleHAiOjE2NzA0MDgzMDcsInBob25lIjoiMzg5NzEzMDkwOTAiLCJ0ZXh0IjoiTGluazpcbmh0dHBzOlwvXC9mYi5tZVwvMmZQOVVLRDZtXG5cbkhlbGxvISBDYW4gSSBnZXQgbW9yZSBpbmZvIG9uIHRoaXM_Iiwic291cmNlX3VybCI6Imh0dHBzOlwvXC9mYi5tZVwvMmZQOVVLRDZtIiwiaWNlYnJlYWtlciI6IkhlbGxvISBDYW4gSSBnZXQgbW9yZSBpbmZvIG9uIHRoaXM_IiwiYXBwIjoiZmFjZWJvb2siLCJlbnRyeV9wb2ludCI6InBvc3RfY3RhIiwiamlkIjoiMzg5NzEzMDkwOTBcdTAwNDBzLndoYXRzYXBwLm5ldCIsInNob3dfa2V5Ym9hcmQiOjEsInNob3dfYWRfYXR0cmlidXRpb24iOjF9.mBuirbTKdFIbv5zJmjtPXzdWX3io5UogLA61WgAqBiIaAV92ZdYtV9bksKjMjffWEdeKn3W4Xzzd2BBlZyjFUQ&fbclid=IwAR3uCxzvyR0IIr0lHuac_nD_JDiNqiGuArJTb3LOUTuhRhykuY5Nytr7Y5U',
  },
  {
    iconName: 'icofont-facebook',
    siteLink: 'https://www.facebook.com/edumindmk',
  },
  {
    iconName: 'icofont-instagram',
    siteLink: 'https://www.instagram.com/edumind.academy/',
  },
  {
    iconName: 'icofont-linkedin',
    siteLink: 'https://www.linkedin.com/company/edumind-academy/',
  },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener('scroll', () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? 'header-fixed fadeInUp' : ''}`}
    >
      <div className={`header-top ${socialToggle ? 'open' : ''}`}>
        <div className='container'>
          <div className='header-top-area'>
            <ul className='lab-ul left'>
              <li>
                <i className='icofont-ui-call'></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className='icofont-location-pin'></i> {address}
              </li>
            </ul>
            <ul className='lab-ul social-icons d-flex align-items-center'>
              <li>
                <p>Mund të na gjeni në: </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink} target='_blank' rel='noreferrer'>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className='header-bottom'>
        <div className='container'>
          <div className='header-wrapper'>
            <div className='logo'>
              <Link to='/'>
                <img src='assets/images/logo/logo.png' alt='logo' />
              </Link>
            </div>
            <div className='menu-area'>
              <div className='menu'>
                <ul className={`lab-ul ${menuToggle ? 'active' : ''}`}>
                  <li>
                    <NavLink to='/'>Ballina</NavLink>
                  </li>
                  <li>
                    <NavLink to='/trajnimet'>Trajnimet</NavLink>
                  </li>
                  {/* <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Blog</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/blog">Blog Grid</NavLink></li>
                                            <li><NavLink to="/blog-2">Blog Style 2</NavLink></li>
                                            <li><NavLink to="/blog-3">Blog Style 3</NavLink></li>
                                            <li><NavLink to="/blog-single">Blog Single</NavLink></li>
                                        </ul>
                                    </li> */}
                  <li>
                    <NavLink to='/rreth-nesh'>Rreth Nesh</NavLink>
                  </li>

                  {/* <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Pages</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/about">About</NavLink></li>
                                            <li><NavLink to="/team">Team</NavLink></li>
                                            <li><NavLink to="/instructor">Instructor</NavLink></li>
                                            <li><NavLink to="/shop">Shop Page</NavLink></li>
                                            <li><NavLink to="/shop-single">Shop Details Page</NavLink></li>
                                            <li><NavLink to="/cart-page">Shop Cart Page</NavLink></li>
                                            <li><NavLink to="/search-page">Search Page</NavLink></li>
                                            <li><NavLink to="/search-none">Search None</NavLink></li>
                                            <li><NavLink to="/404">404</NavLink></li>
                                        </ul>
                                    </li> */}
                  <li>
                    <NavLink to='/na-kontaktoni'>Kontakt</NavLink>
                  </li>

                  <Link
                    to='/regjistrohu'
                    className='signup'
                    style={{ borderRadius: '4px', marginLeft: '15px' }}
                  >
                    <span>Regjistrohu tani</span>{' '}
                  </Link>
                </ul>
              </div>

              <div
                className={`header-bar d-lg-none ${menuToggle ? 'active' : ''}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className='ellepsis-bar d-lg-none'
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className='icofont-info-square'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
