const subTitle = 'Çfarë do të mësoni ?!';
const title = 'Çfarë objektiva do të arrini?';

const featureList = [
  {
    iconName: 'icofont-light-bulb',
    title: 'Përmirësoni shkathtësitë',
    desc: 'Motivi dhe entuziazmi janë çelësi i përmirësimit. Kur ta zotëroni një shkathtësi, zotërojeni më së miri!',
  },
  {
    iconName: 'icofont-graduate',
    title: 'Zhvilloni profesionin',
    desc: 'Vendos qëllime, krijo një plan, rishiko synimet dhe përqendrohu në mësime të vazhdueshme.',
  },
  {
    iconName: 'icofont-users-alt-3',
    title: 'Zgjeroni rrjetin',
    desc: 'Zgjero rrjetin profesional, kështu do të kesh më shumë mundësi informimi dhe ngritje profesionale.',
  },
  {
    iconName: 'icofont-chart-line',
    title: 'Ndërtoni karrierën',
    desc: 'Eksploro, identifiko dhe kërko më shumë mundësi rreth karrierës që do të ndiqni.',
  },
  {
    iconName: 'icofont-dollar',
    title: 'Filloni të fitoni',
    desc: 'Përgatit një CV dhe portfolio me projektet e realizuara. Gjurmoni për mundësitë në kompani të ndryshme.',
  },
  {
    iconName: 'icofont-flag',
    title: 'Rrisni vetëbesimin',
    desc: 'Vetëbesimi është arma më e fuqishme e suksesit. Vlerëso veten, ji pozitiv, e mira padyshim do të gjej.',
  },
];

const FeatureTwo = () => {
  return (
    <section className='feature-section style-2 padding-tb'>
      <div className='container'>
        <div className='section-header text-center'>
          <span className='subtitle' style={{ fontSize: 35, color: 'black' }}>
            {title}
          </span>
          {/* <h2 className="title">{title}</h2> */}
        </div>
        <div className='section-wrapper'>
          <div className='row g-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 justify-content-center'>
            {featureList.map((val, i) => (
              <div className='col' key={i}>
                <div className='feature-item'>
                  <div className='feature-inner'>
                    <div
                      className='feature-icon'
                      style={{
                        backgroundColor: 'white',
                        borderColor: '#26b571',
                      }}
                    >
                      <i
                        className={val.iconName}
                        style={{ color: '#26b571' }}
                      ></i>
                    </div>
                    <div className='feature-content'>
                      <h5 style={{ color: 'black' }}>{val.title}</h5>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureTwo;
