const subTitle = "Rreth Akademisë";
const title = "Trajnime Kualitative, E Ardhme Më e Ndritur";
const desc1 = "Edumind është akademi që fokusohet në ofrimin e edukimit sa më cilësor dhe profesional në fushat e teknologjisë informative, mjekësisë dhe matematikës.";
const desc2 = "Ekip ynë, erudit nga pikëpamja profesionale, unik në veprimtarinë e tyre, dhe raritet në mundësitë që ofrojnë, janë të motivuar dhe plot elan për të shpërndarë diturinë dhe përvojën e tyre tek të rinjtë. Bindshëm, rrugëtimi me ne të bën sinonim suksesi, në fushën që synon!";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Instruktorë me përvojë',
        desc: 'Edumind ofron trajnime nga instruktorë profesional dhe të kualifikuar.',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Pajisu me certifikatë',
        desc: 'Pas çdo moduli të trajnimeve do të pajiseni me certifikatë.',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Platformë interne',
        desc: 'Në këtë platformë kanë qasje vetëm studentët/nxënësit tanë, me mundësi të shumta mësimore në të.',
    },
]


const About = () => {
    return (
        <div className="about-section">
            <div className="container">
                <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                    <div className="col">
                        <div className="about-right padding-tb">
                            <div className="section-header">
                                <span className="subtitle" style={{marginBottom: 20}}>{subTitle}</span>
                                {/* <h2 className="title">{title}</h2> */}
                                <p>{desc1}</p>
                                <br />
                                <p>{desc2}</p>
                            </div>
                            <div className="section-wrapper">
                                <ul className="lab-ul">
                                    {aboutList.map((val, i) => (
                                        <li key={i}>
                                            <div className="sr-left">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="sr-right">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img style={{ 
                                    width: '800px',
                                    maxWidth: '150%',
                                    marginLeft: '-200px'
                                }} src="assets/images/banner/04.png" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;