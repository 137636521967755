import { Link } from 'react-router-dom';

const excenge = 'Ofertë me kohë të kufizuar';
const paymentTitle = 'Secure Payment:';
const shareTitle = 'Share This Course:';

const csdcList = [
  {
    iconName: 'icofont-money',
    leftText: 'Pagesa mujore',
    rightText: '2200 denarë',
  },
  {
    iconName: 'icofont-certificate',
    leftText: 'Certifikatë',
    rightText: 'Po',
  },
  {
    iconName: 'icofont-globe',
    leftText: 'Gjuha',
    rightText: 'Shqip',
  },
];

const socialList = [
  {
    siteLink: '#',
    iconName: 'icofont-twitter',
    className: 'twitter',
  },
  {
    siteLink: '#',
    iconName: 'icofont-vimeo',
    className: 'vimeo',
  },
  {
    siteLink: '#',
    iconName: 'icofont-rss',
    className: 'rss',
  },
];

const CourseSideDetail = (props) => {
  return (
    <div className='course-side-detail'>
      <div className='csd-title'>
        <div className='csdt-left'>
          <h4 className='mb-0'>Çmimi total:</h4>
        </div>
        <div className='csdt-right'>
          <h4 className='mb-0'>{props.coursePrice}</h4>
          {/* <p className="mb-0">
            <i className="icofont-clock-time"></i>
            {excenge}
          </p> */}
        </div>
      </div>
      <div className='csd-content'>
        <div className='csdc-lists'>
          <ul className='lab-ul'>
            {props.courseLevels ? (
              <li>
                <div className='csdc-left'>
                  <i className='icofont-ui-alarm'></i>
                  Nivelet
                </div>
                {props.courseLevels && (
                  <div className='csdc-right'>{props.courseLevels}</div>
                )}
              </li>
            ) : null}
            <li>
              <div className='csdc-left'>
                <i className='icofont-book-alt'></i>
                Kohëzgjatja
              </div>
              <div className='csdc-right'>{props.courseDuration}</div>
            </li>
            <li>
              <div className='csdc-left'>
                <i className='icofont-money'></i>
                Çmimi mujor
              </div>
              <div className='csdc-right'>{props.monthlyPrice}</div>
            </li>
            <li>
              <div className='csdc-left'>
                <i class='icofont-user'></i>
                Mosha
              </div>
              <div className='csdc-right'>{props.recommendedAge}</div>
            </li>
            <li>
              <div className='csdc-left'>
                <i className='icofont-certificate'></i>
                Certifikatë
              </div>
              <div className='csdc-right'>Po</div>
            </li>
            <li>
              <div className='csdc-left'>
                <i className='icofont-globe'></i>
                Gjuha
              </div>
              <div className='csdc-right'>Shqip</div>
            </li>
          </ul>
        </div>
        {/* <div className="sidebar-payment">
          <div className="sp-title">
            <h6>{paymentTitle}</h6>
          </div>
          <div className="sp-thumb">
            <img src="assets/images/pyment/01.jpg" alt="CodexCoder" />
          </div>
        </div> */}
        {/* <div className="sidebar-social">
          <div className="ss-title">
            <h6>{shareTitle}</h6>
          </div>
          <div className="ss-content">
            <ul className="lab-ul">
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink} className={val.className}>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
        <div className='course-enroll'>
          <Link
            to={`/regjistrohu/${props.courseName}`}
            className='lab-btn'
            style={{ backgroundColor: '#26b571' }}
          >
            <span>Regjistrohu tani</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseSideDetail;
