import { Fragment } from "react";
import { Link } from "react-router-dom";
import { courseList } from "../assets/data/courseList";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import GroupSelect from "../component/sidebar/group-select";
// import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
// import SkillSelect from "../component/sidebar/skill-select";=

const CoursePage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Trajnimet"} curPage={"Course Page"} />
      {/* <GroupSelect /> */}
      <div className="course-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="course-showing-part">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="course-showing-part-left">
                  <p>Të gjitha trajnimet</p>
                </div>
              </div>
            </div>
            <div className="row g-4 justify-content-start row-cols-xl-3 row-cols-md-2 row-cols-1">
              {courseList.map((val, i) => (
                <div className="col" key={i}>
                  <Link to={`/${val.courseName}`} style={{ width: '100%' }}>
                    <div className="course-item">
                      <div className="course-inner">
                        <div className="course-thumb">
                          <img
                            style={{ height: "260px", objectFit: "cover" }}
                            src={`${val.imgUrl}`}
                            alt={`${val.imgAlt}`}
                          />
                        </div>
                        <div className="course-content">
                          <div className="course-price">{val.coursePrice}</div>
                          <div className="course-category">
                            <div style={{ backgroundColor: '#edf786', padding: '8px', borderRadius: '5px' }}>
                              <a href="#" style={{ fontWeight: '600' }}>{val.category}</a>
                            </div>
                          </div>
                          <Link to={`/${val.courseName}`}>
                            <h4>{val.courseTitle}</h4>
                          </Link>
                          <div className="course-details">
                              <div className="couse-count">
                                <i class="icofont-user"></i>
                                {val.recommendedAge}
                              </div>
                            <div className="couse-topic">
                              <i className="icofont-signal"></i> {val.schdule}
                            </div>
                          </div>
                          <div className="course-footer">
                          <div
                                  className="course-author"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'center'
                                  }}
                                >
                                  <img
                                    src={`${val.authorImgUrl}`}
                                    style={{ borderRadius: "50%" }}
                                    width="40"
                                    alt={`${val.authorImgAlt}`}
                                  />
                                  <span
                                    className="ca-name"
                                    style={{ marginLeft: '3px' }}
                                  >
                                    {val.authorName}
                                  </span>
                            </div>
                            <div className="course-btn">
                                  <Link 
                                    to={`/${val.courseName}`}
                                    className="lab-btn-text"
                                  >
                                    {val.btnText}
                                    <i className="icofont-external-link"></i>
                                  </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            {/* <Pagination /> */}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CoursePage;
