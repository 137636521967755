const degi = "Instructor";


const socialList = [
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
];

const Author = (props) => {
  return (
    <div className="authors">
      <div className="author-thumb">
        <img src={props.authorImgUrl} alt="rajibraj91" />
      </div>
      <div className="author-content">
        <h5>{props.authorName}</h5>
        <span>{degi}</span>
        {/* <p>{props.authorDescription}</p> */}
        <ul className="lab-ul social-icons">
          {socialList.map((val, i) => (
            <li key={i}>
              <a href={props.authorLinkedIn} target="_blank" className={val.className} rel="noreferrer">
                <i className={val.iconName}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Author;
