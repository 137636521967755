import Rating from "../sidebar/rating";

const title = "Bazat e Uebit";

const reviewCount = "17 reviews";
const categoryList = [
  {
    link: "#",
    text: "Regjistrime të hapura",
    className: "course-cate",
  },
];

const PageHeaderTwo = (props) => {
  return (
    <div className="pageheader-section style-2">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
          <div className="col-lg-5 col-12">
            <div className="pageheader-thumb">
              <img
                src={props.courseImage}
                alt="rajibraj91"
                className="w-100"
                width={100}
              />
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="pageheader-content">
              <div className="course-category">
                {categoryList.map((val, i) => (
                  <a href={val.link} className={val.className} key={i}>
                    {val.text}
                  </a>
                ))}
              </div>
              <h2 className="phs-title">{props.courseTitle}</h2>
              <p className="phs-desc">{props.courseDescription}</p>
              {/* <div className="phs-thumb">
                <img src={props.courseInstructor} alt="rajibraj91" />
                <span>{props.courseAuthor}</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeaderTwo;
