import { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import AchievementTwo from '../component/section/achievement-2';
import Blog from '../component/section/blog';
import Instructor from '../component/section/instructor';
import Skill from '../component/section/skill';
import Student from '../component/section/student';

const subTitle = 'Rreth Akademisë';
const title = 'Trajnime Kualitative, E Ardhme Më e Ndritur';
const desc1 =
  'Edumind është akademi që fokusohet në ofrimin e edukimit sa më cilësor dhe profesional në fushat e teknologjisë informative, mjekësisë dhe matematikës.';
const desc2 =
  'Ekip ynë, erudit nga pikëpamja profesionale, unik në veprimtarinë e tyre, dhe raritet në mundësitë që ofrojnë, janë të motivuar dhe plot elan për të shpërndarë diturinë dhe përvojën e tyre tek të rinjtë. Bindshëm, rrugëtimi me ne të bën sinonim suksesi, në fushën që synon!';

const year = '250+';
const expareance = 'Nxënës të kënaqur';

const aboutList = [
  {
    imgUrl: 'assets/images/about/icon/01.jpg',
    imgAlt: 'about icon rajibraj91 rajibraj',
    title: 'Instruktorë me përvojë',
    desc: 'Edumind ofron trajnime nga instruktorë profesional dhe të kualifikuar.',
  },
  {
    imgUrl: 'assets/images/about/icon/02.jpg',
    imgAlt: 'about icon rajibraj91 rajibraj',
    title: 'Pajisu me certifikatë',
    desc: 'Pas çdo moduli të trajnimeve do të pajiseni me certifikatë.',
  },
  {
    imgUrl: 'assets/images/about/icon/03.jpg',
    imgAlt: 'about icon rajibraj91 rajibraj',
    title: 'Platformë interne',
    desc: 'Në këtë platformë kanë qasje vetëm studentët/nxënësit tanë, me mundësi të shumta mësimore në të.',
  },
];

const AboutPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={'Rreth nesh'} curPage={'About'} />
      <div className='about-section style-3 padding-tb section-bg'>
        <div className='container'>
          <div className='row justify-content-center row-cols-xl-2 row-cols-1 align-items-center'>
            <div className='col'>
              <div className='about-left'>
                <div className='about-thumb'>
                  <img
                    style={{
                      width: '630px',
                      height: '500px',
                      objectFit: 'cover',
                    }}
                    src='assets/images/about/all_instructors.jpg'
                    alt='about'
                  />
                </div>
                {/* <div className="abs-thumb">
                                    <img style={{width: "270px", height: "300px"}} src="assets/images/about/2.png" alt="about" />
                                </div> */}
                <div className='about-left-content'>
                  <h3>{year}</h3>
                  <p>{expareance}</p>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='about-right'>
                <div className='section-header'>
                  <span className='subtitle' style={{ marginBottom: 20 }}>
                    {subTitle}
                  </span>
                  {/* <h2 className="title">{title}</h2> */}
                  <p>{desc1}</p>
                  <br />
                  <p>{desc2}</p>
                </div>
                <div className='section-wrapper'>
                  <ul className='lab-ul'>
                    {aboutList.map((val, i) => (
                      <li key={i}>
                        <div className='sr-left'>
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div>
                        <div className='sr-right'>
                          <h5>{val.title}</h5>
                          <p>{val.desc}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Student /> */}
      <Instructor />
      {/* <Skill /> */}
      {/* <AchievementTwo /> */}
      {/* <Blog /> */}
      <Footer />
    </Fragment>
  );
};

export default AboutPage;
