import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { courseList } from "../../assets/data/courseList";

const subTitle = "Mos i humbisni";
const title = "Top trajnimet";

const CourseThree = () => {
  return (
    <div className="course-section style-2 padding-tb section-bg">
      <div className="container">
        <div className="section-header">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
          <div className="course-navigations">
            <div className="course-navi course-navi-next">
              <i className="icofont-double-left"></i>
            </div>
            <div className="course-navi course-navi-prev">
              <i className="icofont-double-right"></i>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="course-slider p-2">
            <div className="swiper-wrapper">
              <Swiper
                spaceBetween={20}
                slidesPerView={2}
                loop={"true"}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: ".course-navi-prev",
                  nextEl: ".course-navi-next",
                }}
                modules={[Autoplay, Navigation]}
                breakpoints={{
                  0: {
                    width: 0,
                    slidesPerView: 1,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 2.85,
                  },
                }}
              >
                {courseList.map((val, i) => (
                  <SwiperSlide key={i}>
                    <div className="col" key={i}>
                      <Link to={`${val.courseName}`} style={{ width: '100%' }}>
                        <div className="course-item">
                          <div className="course-inner">
                            <div className="course-thumb">
                              <img
                                style={{ height: "260px", objectFit: "cover" }}
                                src={`${val.imgUrl}`}
                                alt={`${val.imgAlt}`}
                              />
                            </div>
                            <div className="course-content">
                              <div className="course-price">{val.coursePrice}</div>
                              <div className="course-category">
                                <div style={{ backgroundColor: '#edf786', padding: '8px', borderRadius: '5px' }}>
                                  <a href="#"  style={{ fontWeight: '600' }}>{val.category}</a>
                                </div>
                              </div>
                              <Link to={`${val.courseName}`}>
                                <h4>{val.courseTitle}</h4>
                              </Link>
                              <div className="course-details">
                                <div className="couse-count">
                                  <i class="icofont-user"></i>
                                  {val.recommendedAge}
                                </div>
                                <div className="couse-topic">
                                  <i className="icofont-signal"></i>
                                  {val.schdule}
                                </div>
                              </div>
                              <div className="course-footer">
                                <div
                                  className="course-author"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'center'
                                  }}
                                >
                                  <img
                                    src={`${val.authorImgUrl}`}
                                    style={{ borderRadius: "50%" }}
                                    width="40"
                                    alt={`${val.authorImgAlt}`}
                                  />
                                  <span
                                    className="ca-name"
                                    style={{ marginLeft: '3px' }}
                                  >
                                    {val.authorName}
                                  </span>
                                </div>
                                <div className="course-btn">
                                  <Link 
                                    to={`/${val.courseName}`}
                                    className="lab-btn-text"
                                  >
                                    {val.btnText}
                                    <i className="icofont-external-link"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/* <div className="course-item style-3">
                                            <div className="course-inner text-center">
                                                <div className="course-thumb">
                                                    <img style={{ height: "260px", objectFit: "cover"}} width="370px" height="260px" src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    <ul className="course-info lab-ul">
                                                        <li><span className="course-name">{val.courseName}</span></li>
                                                        <li><span className="price">{val.coursePrice}</span></li>
                                                        <li>
                                                            <span className="ratting">
                                                                <i className="icofont-ui-rate-blank"></i>
                                                                <i className="icofont-ui-rate-blank"></i>
                                                                <i className="icofont-ui-rate-blank"></i>
                                                                <i className="icofont-ui-rate-blank"></i>
                                                                <i className="icofont-ui-rate-blank"></i>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="course-content">
                                                    <a href="course-single.html"><h4>{val.courseTitle}</h4></a>
                                                    <div className="course-details">
                                                        <div className="couse-count"><i className="icofont-users-alt-3"></i> {val.user}</div>
                                                        <div className="couse-topic"><i className="icofont-speech-comments"></i> {val.commentCount}</div>
                                                    </div>
                                                    <a href="course-single.html" className="lab-btn"><span>{val.btnText}</span></a>
                                                </div>
                                            </div>
                                        </div> */}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseThree;
