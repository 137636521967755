export const courseList = [
  {
    id: 1,
    imgUrl: 'assets/images/course/bota-e-it.jpg',
    category: 'Informatikë',
    imgAlt: 'course rajibraj91 rajibraj',
    courseName: 'bota-e-it-per-femije',
    coursePrice: '395€',
    courseTitle: 'Bota e IT-së për fëmijë',
    courseTitleRegistrations: 'Bota e IT-së për fëmijë (9 - 12 vjet)',
    courseLearningPoints: [
      'Fitoni një kuptim solid të koncepteve të programimit dhe të të menduarit kompjuterik.',
      'Mësoni se si të krijoni histori interaktive, lojëra dhe animacione duke përdorur Scratch.',
      'Zhvilloni aftësi për zgjidhjen e problemeve dhe të menduarit logjik përmes projekteve praktike.',
      'Eksploroni bazat e zhvillimit të aplikacionit duke përdorur App Inventor.',
    ],
    user: '200',
    commentCount: '23',
    btnText: 'Lexo më shumë',
    totalLeson: '18x Leksione',
    schdule: 'Me prezencë fizike',
    authorImgUrl: 'assets/images/instructor/profile-icon.png',
    authorImgAlt: 'course author',
    authorName: 'Naile Toçi Limani',
    authorDescription:
      "I'm an Albanian from North Macedonia a react js developer for the company Technoperia and a guy who does what he loves and loves what he does",
    courseLevels: 2,
    levelTime: '5 muaj',
    courseTime: '11 muaj',
    recommendedAge: '9 - 12 vjet',
    courseDescription:
      'Mirë se vini në Edumind Academy, ku mund të eksploroni botën e IT-së dhe të çlironi krijimtarinë tuaj përmes Scratch dhe App Inventor. Zhytuni në fushën emocionuese të IT-së dhe mësoni bazat e programimit dhe zhvillimit të aplikacioneve. Me Scratch, do të krijoni histori dhe lojëra interaktive duke përdorur blloqe vizuale, ndërsa App Inventor ju fuqizon të ndërtoni aplikacione plotësisht funksionale Android pa kodim. Bashkohuni me ne në këtë udhëtim mahnitës për të transformuar pasionin tuaj në realitete dixhitale në Edumind Academy.',
    courseOverview:
      "Mirë se vini në botën e IT dhe zhvillimit të aplikacioneve! Në këtë kurs, ne do t'ju prezantojmë me sferat magjepsëse të Scratch dhe App Inventor. Bëhuni gati për të eksploruar mundësitë e pakufishme të IT-së dhe zhvillimit të aplikacioneve me ne!",
    courseMidExplanation:
      "Master the basics of web development as you delve into essential concepts, best practices, and techniques required to create impactful projects. Throughout the course, you'll encounter numerous examples that vividly illustrate the key topics covered.",
    courseEndExplanation:
      "Pas përfundimit të trajnimit, ju do të zotëroni njohuritë dhe aftësitë për t'i zbatuar këto parime në mënyrë efektive, duke ju dhënë një bazament të fortë dhe nxitës për vazhdimin e mëtutjeshëm të studimit të shkencave kompjuterike.",
    monthlyPrice: '36€',
    levels: {
      numerOfLevels: 2,
      levelsContent: [
        {
          levelNumber: 1,
          levelTitle: 'Niveli i parë: Hyrje në botën e kompjuterit',
          levelDescription:
            'Ky trajnim ofron një hyrje në Teknologjinë e Informacionit, duke përfshirë tema të tilla si historia e kompjuterit, bazat e harduerit, sistemet operative (me fokus në Windows), aplikacionet softuerike (Microsoft Word, Excel, PowerPoint), përdorimi i internetit, rrjetet, siguria në internet dhe një hyrje në programim duke përdorur mjete të bazuara në blloqe si Scratch. Nxënësit do të fitojnë njohuri dhe aftësi thelbësore të TI-së si përdorimin e kompjuterëve, aplikacioneve të ndryshme, internetit dhe koncepteve bazë të programimit.',
          levelTime: '5 muaj',
          levelCost: '90€',
        },
        {
          levelNumber: 2,
          levelTitle: 'Niveli i dytë: Hyrje në programim',
          levelDescription:
            'Në këtë nivel nxënësit do të kenë mundësinë të njihen me Scratch dhe App Inventor ku këta teknologji janë të përshtatshme për fëmijët që dëshirojnë  të mësojnë programimin dhe zhvillimin e aplikacioneve. Nxënësit me anë të Scratch-it do të krijojnë animacione dhe lojëra duke përdorur blloqe vizuale. Ata gjithashtu do të mësojnë App Inventor për të ndërtuar aplikacione Android pa kodim. Nëpërmjet projekteve praktike, nxënësit zhvillojnë aftësitë e të menduarit llogjik dhe zgjidhjes së problemeve. Në fund, ata mund të krijojnë projektet e tyre dhe të ndjekin studime të mëtejshme në shkenca kompjuterike.',
          levelTime: '6 muaj',
          levelCost: '150€',
        },
      ],
    },
  },
  {
    id: 2,
    imgUrl: 'assets/images/course/course-1.jpg',
    category: 'Web Development',
    imgAlt: 'course author',
    courseName: 'bazat-e-uebit',
    coursePrice: '610€',
    courseTitle: 'Bazat e uebit',
    courseTitleRegistrations: 'Bazat e uebit (12 - 15 vjet)',
    courseLearningPoints: [
      'Zhvilloni projekte të botës reale: Fitoni aftësi praktike për të filluar punën në projekte reale të zhvillimit të uebit.',
      'Zgjeroni rolin tuaj: Rritni përgjegjësitë ekzistuese duke përfshirë aftësitë e zhvillimit të ueb-it.',
      'Krijoni uebfaqe: Mësoni të ndërtoni uebfaqe duke përdorur HTML, CSS, JavaScript dhe Wordpress.',
      'Rritja e pranisë në internet: Përvetësoni aftësi të zhvillimit të uebit për të ngritur praninë tuaj në internet, duke zhbllokuar mundësi të reja.',
      'Përvetësoni dizajnin e ueb-it: Krijoni përvoja magjepsëse në ueb duke përdorur ngjyrat, gradientë dhe gridat.',
    ],
    user: '200',
    commentCount: '23',
    btnText: 'Lexo më shumë',
    totalLeson: '18x Leksione',
    schdule: 'Me prezencë fizike',
    authorImgUrl: 'assets/images/instructor/agron.jpg',
    authorImgAlt: 'course author',
    linkedIn: 'https://www.linkedin.com/in/agron-bajrami-78a18a213/',
    authorName: 'Agron Bajrami',
    authorDescription:
      "I'm an Albanian from North Macedonia a Full Stack Developer for the company AppGreat and a guy who does what he loves and loves what he does",
    courseLevels: 3,
    levelTime: '5 muaj',
    courseTime: '15 muaj',
    recommendedAge: '13 - 15 vjet',
    courseDescription:
      'Mirë se vini në Edumind Academy, porta juaj për zhvillimin e uebit. Eksploroni HTML, CSS dhe Wordpress ku do të ju mundësojnë përvoja magjepsëse në ueb. Krijoni paraqitje mahnitëse, angazhohuni me pamjet vizuale dhe mbushni funksionalitetin dinamik. Lëshoni kreativitetin tuaj dhe filloni një udhëtim fuqizues drejt bazave në Edumind Academy. Shndërroni pasionin tuaj në realitet.',
    courseOverview:
      "Mirë se vini në botën e zhvillimit të uebit! Në këtë përmbledhje të shkurtër të trajnimit, ne do t'ju udhëzojmë nëpër blloqet themelore të ndërtimit të krijimit të ueb faqeve mahnitëse. Bëhuni gati të zhbllokoni kreativitetin tuaj dhe të zhyteni në magjinë e HTML, CSS, Bootstrap, JavaScript dhe Wordpress. Deri në fund të këtij kursi, ju do të keni aftësitë për të krijuar ueb faqe të bukura dhe interaktive që magjepsin audiencën tuaj. Le të fillojmë së bashku këtë udhëtim emocionues!",
    courseMidExplanation:
      "Master the basics of web development as you delve into essential concepts, best practices, and techniques required to create impactful projects. Throughout the course, you'll encounter numerous examples that vividly illustrate the key topics covered.",
    courseEndExplanation:
      "Pas përfundimit të trajnimit, ju do të zotëroni njohuritë dhe aftësitë për t'i zbatuar këto parime në mënyrë efektive, duke ju fuqizuar për të marrë vendime të sakta  për zhvillimin dhe projektimin e pjesës së përparme(front-end) të ueb-it.",
    monthlyPrice: '40€',
    levels: {
      numerOfLevels: 3,
      levelsContent: [
        {
          levelNumber: 1,
          levelTitle: 'Niveli 1: HTML, CSS dhe Bootstrap',
          levelDescription:
            'Në këtë nivel, nxënësit do të mësojnë bazat e HTML, duke përfshirë strukturën e dokumentit, formatimin e tekstit, krijimin e lidhjeve dhe navigimin, punën me imazhe, tabela, forma dhe kuptimin e semantikës. Gjithashtu në CSS do të mësojnë dizajnimin e ueb-it, duke përfshirë ngjyrat, fontet, organizimin e elementeve dhe paraqitja e tyre në mënyrë korrekte në të gjitha madhësit e ndryshme të ekraneve. Poashtu do të përfshihet udhëzim për përdorimin e Bootstrap-it.',
          levelTime: '5 muaj',
          levelCost: '180€',
        },
        {
          levelNumber: 2,
          levelTitle: 'Niveli 2: Javascript',
          levelDescription:
            "Në këtë nivel, nxënësit do të mësojnë bazat e gjuhës programuese JavaScript për zhvillimin e uebit. Ato do t'i përfshijnë temat të tilla si variablat, llojet e të dhënave, operatorët, strukturat e kontrollit (if statements, loops), funksionet dhe vargjet. Studentët do të eksplorojnë gjithashtu manipulimin e DOM-it për të bashkëvepruar me elementet në HTML, për të trajtuar eventet, për të kryer vërtetimin e formularit dhe për të bërë kërkesa me AJAX(AJAX requests). Përveç kësaj, ata do të mësojnë rreth koncepteve të programimit të orientuar drejt objektit(object-oriented programming concepts), programimit asinkron duke përdorur PROMISES ose ASYNC/AWAIT, dhe trajtimin e gabimeve bazë. Ushtrime dhe projekte praktike do të ofrohen për të aplikuar aftësitë JavaScript në ndërtimin e aplikacioneve interaktive në ueb.",
          levelTime: '5 muaj',
          levelCost: '180€',
        },
        {
          levelNumber: 3,
          levelTitle: 'Niveli 3: Wordpress',
          levelDescription:
            'Në këtë nivel, nxënësit do të pajisen me njohuri dhe aftësi për të krijuar dhe menaxhuar uebfaqe profesionale duke përdorur sistemin e menaxhimit të përmbajtjes WordPress (CMS). Nxënësit do të mësojnë se si të konfigurojnë një faqe WordPress, të personalizojnë pamjen e saj duke përdorur tema dhe shabllone dhe të zgjerojnë funksionalitetin e saj me shtojca. ',
          levelTime: '5 muaj',
          levelCost: '180€',
        },
      ],
    },
  },
  {
    id: 3,
    imgUrl: 'assets/images/course/front-end.jpg',
    category: 'Web Development',
    imgAlt: 'course',
    courseName: 'front-end-zhvillues',
    coursePrice: '610€',
    courseTitle: 'Front End Zhvillues',
    courseTitleRegistrations: 'Front-end zhvillues (16 - 19 vjet)',
    courseLearningPoints: [
      'Zhvilloni projekte të botës reale: Fitoni aftësi praktike për të filluar punën në projekte reale të zhvillimit të uebit.',
      'Zgjeroni rolin tuaj: Rritni përgjegjësitë ekzistuese duke përfshirë aftësitë e zhvillimit të ueb-it.',
      'Krijoni uebfaqe: Mësoni të ndërtoni uebfaqe duke përdorur HTML, CSS, Bootstrap, JavaScript dhe React JS.',
      'Rritja e pranisë në internet: Përvetësoni aftësi të zhvillimit të uebit për të ngritur praninë tuaj në internet, duke zhbllokuar mundësi të reja.',
      'Përvetësoni dizajnin e ueb-it: Krijoni përvoja magjepsëse në ueb duke përdorur ngjyrat, gradientë dhe gridat.',
    ],
    user: '200',
    commentCount: '23',
    btnText: 'Lexo më shumë',
    totalLeson: '18x Leksione',
    schdule: 'Me prezencë fizike',
    authorImgUrl: 'assets/images/instructor/jeton.jpg',
    authorImgAlt: 'course author',
    linkedIn: 'https://www.linkedin.com/in/jeton-ramadani-298b9a151/',
    authorName: 'Jeton Ramadani',
    authorDescription:
      "I'm an Albanian from North Macedonia a react js developer for the company Technoperia and a guy who does what he loves and loves what he does",
    courseLevels: 3,
    levelTime: '5 muaj',
    courseTime: '15 muaj',
    recommendedAge: '16 - 18 vjet',
    courseDescription:
      'Mirë se vini në Edumind Academy, porta juaj për zhvillimin e uebit. Eksploroni HTML, CSS dhe React JS ku do të ju mundësojnë përvoja magjepsëse në ueb. Krijoni paraqitje mahnitëse, angazhohuni me pamjet vizuale dhe mbushni funksionalitetin dinamik. Lëshoni kreativitetin tuaj dhe filloni një udhëtim fuqizues drejt bazave në Edumind Academy. Shndërroni pasionin tuaj në realitet.',
    courseOverview:
      "Mirë se vini në botën e zhvillimit të uebit! Në këtë përmbledhje të shkurtër të trajnimit, ne do t'ju udhëzojmë nëpër blloqet themelore të ndërtimit të krijimit të ueb faqeve mahnitëse. Bëhuni gati të zhbllokoni kreativitetin tuaj dhe të zhyteni në magjinë e HTML, CSS, Bootstrap, JavaScript dhe React JS. Deri në fund të këtij kursi, ju do të keni aftësitë për të krijuar ueb faqe të bukura dhe interaktive që magjepsin audiencën tuaj. Le të fillojmë së bashku këtë udhëtim emocionues!",
    courseMidExplanation:
      "Master the basics of web development as you delve into essential concepts, best practices, and techniques required to create impactful projects. Throughout the course, you'll encounter numerous examples that vividly illustrate the key topics covered.",
    courseEndExplanation:
      "Pas përfundimit të trajnimit, ju do të zotëroni njohuritë dhe aftësitë për t'i zbatuar këto parime në mënyrë efektive, duke ju fuqizuar për të marrë vendime të sakta  për zhvillimin dhe projektimin e pjesës së përparme(front-end) të ueb-it.",
    monthlyPrice: '40€',
    levels: {
      numerOfLevels: 3,
      levelsContent: [
        {
          levelNumber: 1,
          levelTitle: 'Niveli 1: HTML, CSS dhe Bootstrap',
          levelDescription:
            'Në këtë nivel, nxënësit do të mësojnë bazat e HTML, duke përfshirë strukturën e dokumentit, formatimin e tekstit, krijimin e lidhjeve dhe navigimin, punën me imazhe, tabela, forma dhe kuptimin e semantikës. Gjithashtu në CSS do të mësojnë dizajnimin e ueb-it, duke përfshirë ngjyrat, fontet, organizimin e elementeve dhe paraqitja e tyre në mënyrë korrekte në të gjitha madhësit e ndryshme të ekraneve. Poashtu do të përfshihet udhëzim për përdorimin e Bootstrap-it.',
          levelTime: '5 muaj',
          levelCost: '180€',
        },
        {
          levelNumber: 2,
          levelTitle: 'Niveli 2: Javascript',
          levelDescription:
            "Në këtë nivel, nxënësit do të mësojnë bazat e gjuhës programuese JavaScript për zhvillimin e uebit. Ato do t'i përfshijnë temat të tilla si variablat, llojet e të dhënave, operatorët, strukturat e kontrollit (if statements, loops), funksionet dhe vargjet. Nxënësit do të eksplorojnë gjithashtu manipulimin e DOM-it për të bashkëvepruar me elementet në HTML, për të trajtuar eventet, për të kryer vërtetimin e formularit dhe për të bërë kërkesa me AJAX(AJAX requests). Përveç kësaj, ata do të mësojnë rreth koncepteve të programimit të orientuar drejt objektit(object-oriented programming concepts), programimit asinkron duke përdorur PROMISES ose ASYNC/AWAIT, dhe trajtimin e gabimeve bazë. Ushtrime dhe projekte praktike do të ofrohen për të aplikuar aftësitë JavaScript në ndërtimin e aplikacioneve interaktive në ueb.",
          levelTime: '5 muaj',
          levelCost: '180€',
        },
        {
          levelNumber: 3,
          levelTitle: 'Niveli 3: React JS',
          levelDescription:
            'Në këtë nivel nxënësit do të njihen për së afërmi me teknologjinë moderne e cila ka një përdorim të gjerë ne industrinë e IT-së, e cila është React JS. Nxënësit do të mësojnë bazat e React JS, një bibliotekë e famshme e JavaScript për ndërtimin e uebfaqeve interaktive ku do të mësohen konceptet thelbësore të React-it, duke përfshirë komponentët, state, props dhe sintaksën JSX. Nëpërmjet ushtrimeve dhe projekteve praktike, nxënësit do të fitojnë aftësi në krijimin e komponentëve UI të ripërdorshme, menaxhimin e state-it së aplikacionit dhe trajtimin e eventeve nga shfrytëzuesit. Ata gjithashtu do të eksplorojnë tema të avancuara si routing, trajtimi i formularëve dhe menaxhimi i të dhënave duke përdorur React hooks dhe biblioteka tjera. Pas përfundimit të këtij niveli nxënësit do të jenë në gjendje të ndërtojnë aplikacione të qëndrueshme dhe stabile duke përdorur React JS, duke u mundësuar atyre të krijojnë përvoja dinamike dhe tërheqëse të përdoruesve për projektet ose bizneset e tyre.',
          levelTime: '5 muaj',
          levelCost: '180€',
        },
      ],
    },
  },
  {
    id: 4,
    imgUrl: 'assets/images/course/front-end-react.jpg',
    category: 'Web Development',
    imgAlt: 'course',
    courseName: 'front-end-zhvillues-1',
    coursePrice: '900€',
    courseTitle: 'Akademia për Front End Zhvillues',
    courseTitleRegistrations: 'Front-end zhvillues (mbi 18 vjet)',
    courseLearningPoints: [
      'Zhvilloni projekte të botës reale: Fitoni aftësi praktike për të filluar punën në projekte reale të zhvillimit të uebit.',
      'Zgjeroni rolin tuaj: Rritni përgjegjësitë ekzistuese duke përfshirë aftësitë e zhvillimit të ueb-it.',
      'Krijoni uebfaqe: Mësoni të ndërtoni uebfaqe duke përdorur HTML, CSS, Sass, Bootstrap, Git, JavaScript, React JS dhe Redux JS.',
      'Rritja e pranisë në internet: Përvetësoni aftësi të zhvillimit të uebit për të ngritur praninë tuaj në internet, duke zhbllokuar mundësi të reja.',
      'Përvetësoni dizajnin e ueb-it: Krijoni përvoja magjepsëse në ueb duke përdorur ngjyrat, gradientë dhe gridat.',
    ],
    user: '200',
    commentCount: '23',
    btnText: 'Lexo më shumë',
    totalLeson: '18x Leksione',
    schdule: 'Me prezencë fizike / online',
    authorImgUrl: 'assets/images/instructor/skender.jpg',
    authorImgAlt: 'course author',
    linkedIn: 'https://www.linkedin.com/in/skender-mustafa-587548125/',
    authorName: 'Skender Mustafa',
    authorDescription:
      "I'm an Albanian from North Macedonia a react js developer for the company Technoperia and a guy who does what he loves and loves what he does",
    courseLevels: 0,
    levelTime: '5 muaj',
    courseTime: '8 muaj',
    recommendedAge: 'mbi 18 vjet',
    courseDescription:
      'Mirë se vini në Edumind Academy, porta juaj për zhvillimin e uebit. Eksploroni HTML, CSS, Sass, Bootstrap, Git, Javascript, React JS dhe Redux JS ku do të ju mundësojnë përvoja magjepsëse në ueb. Krijoni paraqitje mahnitëse, angazhohuni me pamjet vizuale dhe mbushni funksionalitetin dinamik. Lëshoni kreativitetin tuaj dhe filloni një udhëtim fuqizues drejt bazave në Edumind Academy. Shndërroni pasionin tuaj në realitet.',
    courseOverview:
      "Mirë se vini në botën e zhvillimit të uebit! Në këtë përmbledhje të shkurtër të trajnimit, ne do t'ju udhëzojmë nëpër blloqet themelore të ndërtimit të krijimit të ueb faqeve mahnitëse. Bëhuni gati të zhbllokoni kreativitetin tuaj dhe të zhyteni në magjinë e këtyre teknologjive. Deri në fund të këtij kursi, ju do të keni aftësitë për të krijuar ueb faqe të bukura dhe interaktive që magjepsin audiencën tuaj. Le të fillojmë së bashku këtë udhëtim emocionues!",
    courseMidExplanation:
      "Master the basics of web development as you delve into essential concepts, best practices, and techniques required to create impactful projects. Throughout the course, you'll encounter numerous examples that vividly illustrate the key topics covered.",
    courseEndExplanation:
      "Pas përfundimit të trajnimit, ju do të zotëroni njohuritë dhe aftësitë për t'i zbatuar këto parime në mënyrë efektive, duke ju fuqizuar për të marrë vendime të sakta  për zhvillimin dhe projektimin e pjesës së përparme(front-end) të ueb-it.",
    monthlyPrice: '112€',
    levels: {
      numerOfLevels: 3,
      levelsContent: [
        {
          levelNumber: 1,
          levelTitle: 'HTML, CSS, Sass dhe Bootstrap',
          levelDescription:
            'Në këtë nivel, studentët do të mësojnë bazat e HTML, duke përfshirë strukturën e dokumentit, formatimin e tekstit, krijimin e lidhjeve dhe navigimin, punën me imazhe, tabela, forma dhe kuptimin e semantikës. Gjithashtu në CSS do të mësojnë dizajnimin e ueb-it, duke përfshirë ngjyrat, fontet, organizimin e elementeve dhe paraqitja e tyre në mënyrë korrekte në të gjitha madhësit e ndryshme të ekraneve. Poashtu do të përfshihet udhëzim për përdorimin e Sass-it dhe Bootstrap-it.',
          levelTime: '2 muaj',
          levelCost: '180€',
        },
        {
          levelNumber: 2,
          levelTitle: 'Javascript, Git dhe Github',
          levelDescription:
            "Në këtë nivel, studentët do të mësojnë bazat e gjuhës programuese JavaScript për zhvillimin e uebit. Ato do t'i përfshijnë temat të tilla si variablat, llojet e të dhënave, operatorët, strukturat e kontrollit (if statements, loops), funksionet dhe vargjet. Studentët do të eksplorojnë gjithashtu manipulimin e DOM-it për të bashkëvepruar me elementet në HTML, për të trajtuar eventet, për të kryer vërtetimin e formularit dhe për të bërë kërkesa me AJAX(AJAX requests). Përveç kësaj, ata do të mësojnë rreth koncepteve të programimit të orientuar drejt objektit(object-oriented programming concepts), programimit asinkron duke përdorur PROMISES ose ASYNC/AWAIT, dhe trajtimin e gabimeve bazë. Ushtrime dhe projekte praktike do të ofrohen për të aplikuar aftësitë JavaScript në ndërtimin e aplikacioneve interaktive në ueb. Poashtu studentët tanë do të mësojnë koceptet e Git-it dhe përdorimin e tij në proekte reale.",
          levelTime: '3 muaj',
          levelCost: '180€',
        },
        {
          levelNumber: 3,
          levelTitle:
            'React JS, Redux JS dhe përgatitje për intervistë teknike',
          levelDescription:
            'Në këtë nivel nxënësit do të njihen për së afërmi me teknologjinë moderne e cila ka një përdorim të gjerë ne industrinë e IT-së, e cila është React JS. Nxënësit do të mësojnë bazat e React JS, një bibliotekë e famshme e JavaScript për ndërtimin e uebfaqeve interaktive ku do të mësohen konceptet thelbësore të React-it, duke përfshirë komponentët, state, props dhe sintaksën JSX. Nëpërmjet ushtrimeve dhe projekteve praktike, nxënësit do të fitojnë aftësi në krijimin e komponentëve UI të ripërdorshme, menaxhimin e state-it së aplikacionit dhe trajtimin e eventeve nga shfrytëzuesit. Ata gjithashtu do të eksplorojnë tema të avancuara si routing, trajtimi i formularëve dhe menaxhimi i të dhënave duke përdorur React hooks dhe librari tjera. Gjithashtu studentët do të përgatiten për intervistë teknike që do të kenë gjatë aplikimit në kompani të ndryshme. Pas përfundimit të këtij niveli nxënësit do të jenë në gjendje të ndërtojnë aplikacione të qëndrueshme dhe stabile duke përdorur React JS dhe Redux JS (librari për menaxhimin e state-it), duke u mundësuar atyre të krijojnë përvoja dinamike dhe tërheqëse të përdoruesve për projektet ose bizneset e tyre.',
          levelTime: '3 muaj',
          levelCost: '180€',
        },
      ],
    },
  },
  {
    id: 5,
    imgUrl: 'assets/images/course/software-engineer.jpg',
    category: 'Web Development',
    imgAlt: 'course',
    courseName: 'inxhinieri-softuerike',
    coursePrice: '1120€ (me zbritje)',
    courseTitle: 'Akademia për Inxhinieri Softuerike',
    courseTitleRegistrations: 'Inxhinieri Softuerike (mbi 16 vjet)',
    courseLearningPoints: [
      'Analiza dhe Zhvillimi i Softuerit: Fitoni njohuri të thella për ciklin e jetës së zhvillimit të softuerit dhe praktikat e dobishme për të filluar punën në projekte reale.',
      'Dizajn dhe Zhvillim i Faqeve të Internetit: Mësoni të ndërtoni faqe të internetit të bukura dhe interaktive duke përdorur gjuhët programuese si HTML, CSS, JavaScript, si dhe framework-ët si React ose Angular.',
      'Praktikat e Bashkëpunimit dhe Metodologjia Scrum: Përfitoni njohuri në rolin e bashkëpunimit në ekip dhe praktikat e metodologjisë Scrum për të menaxhuar projektet me sukses.',
      'Arkitektura dhe Dizajni i Softuerit: Eksploroni modele arkitektonike dhe praktikat më të mira për të projektuar dhe zhvilluar aplikacione të sofistikuara.',
      'Praktikat e Zhvillimit të Vazhdueshëm dhe DevOps: Mësoni teknikat dhe metodat e integrimit të vazhdueshëm dhe zhvillimit për të menaxhuar dhe lançuar aplikacione me sukses në ambientet e prodhimit.',
    ],
    user: '200',
    commentCount: '23',
    btnText: 'Lexo më shumë',
    totalLeson: '18x Leksione',
    schdule: 'Me prezencë fizike/online',
    authorImgUrl: 'assets/images/instructor/edmond-2.jpg',
    authorImgAlt: 'course author',
    linkedIn: 'https://www.linkedin.com/in/edmond-jajaga-88a5406/',
    authorName: 'Prof. Dr. Edmond Jajaga',
    authorDescription:
      "I'm an Albanian from North Macedonia a react js developer for the company Technoperia and a guy who does what he loves and loves what he does",
    courseLevels: 0,
    levelTime: '8 muaj',
    courseTime: '8 muaj',
    recommendedAge: 'mbi 16 vjet',
    courseDescription:
      'Mirë se vini në Edumind Academy, hapësirën  tuaj për zhvillimin e softuerit. Eksploroni parimet, konceptet dhe praktikat e nevojshme për të filluar një karrierë të suksesshme si zhvillues softueri. Nëpërmjet kursit tonë, do të fitoni njohuri të përshtatshme për të përparuar në botën e zhvillimit të faqeve të uebit. Me ndihmën tonë, do të shndërroni pasionin për zhvillimin e softuerit në një realitet të qëndrueshëm.',
    courseOverview:
      'Mirë se vini në botën e zhvillimit të softuerit! Në këtë përmbledhje të shkurtër të kursit tonë, ne do t’ju udhëzojmë nëpër blloqet themelore të inxhinierisë së softuerit dhe krijimit të produkteve të sofistikuara softuerike. Bëhuni gati të zhbllokoni potencialin tuaj dhe të zhyteni në magjinë e këtyre teknologjive. Deri në fund të këtij kursi, ju do të keni aftësitë për të krijuar aplikacione të sofistikuara dhe do të jeni të aftë për të menaxhuar projekte të mëdha në botën e zhvillimit të softuerit. Le të fillojmë së bashku këtë udhëtim emocionues!',
    courseMidExplanation:
      "Master the basics of web development as you delve into essential concepts, best practices, and techniques required to create impactful projects. Throughout the course, you'll encounter numerous examples that vividly illustrate the key topics covered.",
    courseEndExplanation:
      'Pas përfundimit të kursit, ju do të zotëroni njohuritë dhe aftësitë për të zbatuar këto parime në mënyrë efektive, duke ju fuqizuar për të marrë vendime të sakta për zhvillimin e softuerit në industri.',
    monthlyPrice: '140€',
    levels: {
      numerOfLevels: 2,
      levelsContent: [
        {
          levelNumber: 1,
          levelTitle: 'Hyrja në Inxhinierinë e Softuerit',
          levelDescription:
            'Nxënësit (Javët 1-2) do të shkëmbejnë njohuri themelore mbi inxhinierinë e softuerit dhe do të njihen me ciklin jetësor të zhvillimit të softuerit. Përmes një gjuhe programimi të përshtatshme për fillestarët si C#, ata do të eksplorojnë strukturën e kodit dhe sintaksën bazë. Më pas (Javët 3-4) kursi do të përmbajë njohuri thelbësore mbi parimet e dizajnit të softuerit. Nxënësit do të fokusohen në dizajnin e produkteve softuerike dhe do të mësojnë parimet e dizajnit inxhinierik, duke u përqëndruar në zgjidhjen e problemeve dhe mendimin si dizajnues. Më tutje (Javët 5-8) nxënësit do të zhyten në themelet e Uebit duke përdorur teknologjitë HTML, CSS dhe JavaScript. Do të eksplorojnë kornizat e faqeve të uebit si React ose Angular për të krijuar faqe të internetit tërheqëse dhe të përshtatshme për përdoruesit. Në vazhdim (Javët 9-12)  nxënësit do të fokusohen në programimin në anën e serverit duke përdorur gjuhë programimi si C#, Node.js, Python ose Java. Do të ndërtojnë API-t dhe do të mësojnë të menaxhojnë bazat e të dhënave duke përdorur teknologjitë SQL dhe NoSQL, si dhe lidhjen e tyre me aplikacionet përmes ORM-ve. Në fund të nivelit (Javët 13-16) nxënësit do të njihen me metodën Scrum dhe praktikat e bashkëpunimit në zhvillimin e softuerit. Ata do të eksplorojnë rolet e Scrum, eventet e tij dhe praktikat bashkëpunuese të zhvillimit.',
          levelTime: '4 muaj',
          levelCost: '400€',
        },
        {
          levelNumber: 2,
          levelTitle: ' Inxhinieria e Softuerit e Avancuar',
          levelDescription:
            'Në fillim (Javët 1-4) nxënësit do të eksplorojnë modelet arkitekturore si MVC dhe RESTful, duke analizuar dhe dizajnuar aplikacione të sofistikuara. Do të mësojnë parimet dhe praktikat më të mira të dizajnit për të siguruar performancë dhe shkallëzim të aplikacioneve. Pas kësaj (Javët 5-8) do të njihen me praktikat e DevOps dhe teknikat e zhvillimit dhe integrimit të vazhdueshëm (CI/CD), duke përfshirë shpërndarjet automatike të aplikacioneve. Në vazhdim (Javët 9-12) nxënësit do të mësojnë teknologjitë e fundit për zhvillimin e faqeve të uebit si SPA dhe menaxhimin e gjendjes së aplikacionit. Do të trajtojnë koncepte të larta të UI/UX për të krijuar përvoja të përdoruesve tërheqëse dhe intuitive.  Pjesa përfundimtare e kursit (Javët 13-14) do të përfshijë një projekt të mbajtur në nivelin më të lartë të cilin nxënësit do ta përfundojnë duke kombinuar njohuritë dhe shkathtësitë e tyre. Përveç kësaj, ata do të përgatiten për të hyrë në tregun e punës, duke përfshirë ndërtimin e CV-së, përgatitjen për intervistën dhe një pasqyrë rreth tendencave të industrisë.',
          levelTime: '4 muaj',
          levelCost: '400€',
        },
      ],
    },
  },
  {
    id: 6,
    imgUrl: 'assets/images/course/ui-ux.png',
    category: 'Design',
    imgAlt: 'course',
    courseName: 'ui-ux-design',
    coursePrice: '640€ (me zbritje)',
    courseTitle: 'UI/UX Design',
    courseTitleRegistrations: 'UI/UX Design (mbi 16 vjet)',
    courseLearningPoints: [
      'Zhvilloni përvoja të plota dixhitale për përdoruesit: Nëpërmjet praktikave të UI/UX dizajnit, do të fitoni aftësinë për të krijuar përvoja të kënaqshme dixhitale, që japin një ndjesi të përsosshmërisë dhe të përshtatshme për audiencën.',
      'Krijoni dizajne tërheqëse dhe funksionale: Ju do të mësoni të krijoni dizajne tërheqëse dhe efektive për ueb faqet duke përdorur ngjyrat, gradientë dhe gridat në mënyrë që të përshtaten me nevojat e përdoruesve.',
      'Përvetësoni aftësitë praktike: Nëpërmjet projekteve të botës reale dhe trajnimit të specializuar, do të zhvilloni aftësitë për të aplikuar njohuritë në praktikë dhe për të kuptuar si të përmirësoni përvojën e përdoruesit në ueb faqe.',
      'Ngritja e pranisë në internet: Me një fokus në dizajnin e përvojës së përdoruesit dhe dizajnin e ndërfaqes së përdoruesit, do të mësoni se si të ngrini praninë tuaj në internet duke përdorur strategji efektive të dizajnit.',
      'Eksploroni teknologjitë e reja: Nëpërmjet një gjithepërfshirjeje të teknologjive të fundit, si dhe praktikave të mira të dizajnit, do të eksploroni mënyra të reja për të përmbushur objektivat e UX/UI dizajnit.',
    ],
    user: '200',
    commentCount: '23',
    btnText: 'Lexo më shumë',
    totalLeson: '18x Leksione',
    schdule: 'Me prezencë fizike/online',
    authorImgUrl: 'assets/images/instructor/rezon-2.jpg',
    authorImgAlt: 'course author',
    linkedIn: 'https://www.linkedin.com/in/rezon-karpuzi/',
    authorName: 'Rezon Karpuzi',
    authorDescription:
      "I'm an Albanian from North Macedonia a react js developer for the company Technoperia and a guy who does what he loves and loves what he does",
    courseLevels: 0,
    levelTime: '6 muaj',
    courseTime: '6 muaj',
    recommendedAge: 'mbi 16 vjet',
    courseDescription:
      'Mirë se vini në Edumind Academy, hapësira juaj për eksplorimin dhe zhvillimin e dizajnit të përvojës së përdoruesit (UX) dhe dizajnit të ndërfaqes së përdoruesit (UI). Në këtë kurs, do të zhytemi në thelbin e dizajnit të uebit, ndërsa  përmes projekteve praktike dhe trajnimit të specializuar në teknologjitë  më të fundit të industrisë së dizajnit dixhital, do të zhvillojmë aftësitë tuaja për të krijuar përvoja mahnitëse për përdoruesit dhe dizajne të ndërfaqes dixhitale tërheqëse, të dalluara  dhe të qarta. Do të ndezim llambën e kreativitetit tuaj, në menyrë që  të filloni një udhëtim të frytshëm drejt specializimit në UX/UI dizajn në Edumind Academy. Ndiqni  pasionin tuaj dhe shndërroni vizionet tuaja në realitet.',
    courseOverview:
      'Mirë se vini në botën e dizajnit të përvojës së përdoruesit (UX) dhe dizajnit të ndërfaqes së përdoruesit (UI) në Edumind Academy! Në këtë kurs inovativ, ju do të eksploroni thelbin e krijimit të përvojave të përsosuar të përdoruesve dhe dizajnit të shkëlqyer të ndërfaqes së përdoruesit për ueb faqet e ndryshme. Ky është një udhëtim ku ju do të zhyteni në thellësitë e dizajnit të ngjyrave, tipografisë dhe strukturës së uebit, duke krijuar përvoja që tërheqin dhe përfshijnë audiencë e synuar. Le të fillojmë së bashku këtë udhëtim emocionues!',
    courseMidExplanation:
      "Master the basics of web development as you delve into essential concepts, best practices, and techniques required to create impactful projects. Throughout the course, you'll encounter numerous examples that vividly illustrate the key topics covered.",
    courseEndExplanation:
      'Pas përfundimit të trajnimit, ju do të keni aftësinë për të përmbushur nevojat e përdoruesve dhe për të krijuar përvoja mahnitëse për audiencën. Shndërroni pasionin tuaj në realitet!',
    monthlyPrice: '107€',
    levels: {
      numerOfLevels: 8,
      levelsContent: [
        {
          levelNumber: 1,
          levelTitle: '1. Hyrje',
          levelDescription:
            'Hyrje ne fushën e Dizajnit duke eksploruar rolin e dizajnit në industritë e ndryshme dhe njohja e punëve të ndryshme brenda tij (UX Designer, UI Designer, UX Writer, UX Researcher).',
          levelTime: '',
          levelCost: '',
        },
        {
          levelNumber: 2,
          levelTitle: '2. Design Thinking dhe faza fillestare e një projekti',
          levelDescription:
            'Kuptimi i mendimit dizajnues dhe zbatimi i tij në fazën fillestare të një projekti, duke përfshirë kërkesat për përdorshmëri (Usability Requirements) dhe intervistat me përdoruesit (Stakeholder Interview)',
          levelTime: '',
          levelCost: '',
        },
        {
          levelNumber: 3,
          levelTitle: '3. Discovery dhe metodat e hulumtimit',
          levelDescription:
            'Përdorimi i parashikimeve (Assumptions) dhe metodave cilësore dhe sasiore të kërkimit për të krijuar persona të përdoruesve të besueshëm (user persona)',
          levelTime: '',
          levelCost: '',
        },
        {
          levelNumber: 4,
          levelTitle: '4. Analiza',
          levelDescription:
            'Analiza e konkurrencës dhe identifikimi i mundësive përparëse (SWOT) në përmbledhje, si dhe ndërtimi i udhëtimeve të përdoruesve  (user journey) dhe arkitekturës së informacionit për ueb faqet (information architecture, site maps, hierarchy, card sorting), dhe projektimi i një sajti duke përdorur Miro.',
          levelTime: '',
          levelCost: '',
        },
        {
          levelNumber: 5,
          levelTitle: '5. Mjetet (tools)',
          levelDescription:
            'Zhytja e thellë në mjete të dizajnit si Figma dhe Miro / FigJam, duke përfshirë praktikat përkatëse në përdorimin e tyre (components, libraries, design systems, prototyping).',
          levelTime: '',
          levelCost: '',
        },
        {
          levelNumber: 6,
          levelTitle: '6. User Interface',
          levelDescription:
            'Përmbledhje e konsistencës, tipografisë, ngjyrave, ikonave, wireframes dhe dizajnit për përdoruesit me aftësi të kufizuara, duke përfshirë praktikat përkatëse për të arritur një dizajn të përshtatshëm për të gjitha audiencat.',
          levelTime: '',
          levelCost: '',
        },
        {
          levelNumber: 7,
          levelTitle: '7. Storytelling with Design / Case Study',
          levelDescription:
            'Zbatimi i dizajnit nëpërmjet një studimi rasti në formë të rrefimit dhe një përshkrimi të ngjashëm të aplikimeve të dizajnit për një përvojë më të mirë të përdoruesit.',
          levelTime: '',
          levelCost: '',
        },
        {
          levelNumber: 8,
          levelTitle: '8. Projekti Final',
          levelDescription: '',
          levelTime: '',
          levelCost: '',
        },
      ],
    },
  },
];
